<template>
  <div>
    <base-card class="space-y-4" sm-only>
      <base-card sm-none class="space-y-4">
        <img src="@/assets/images/kop.png" width="300px" />
        <div class="grid items-center gap-4 md:grid-cols-2">
          <div>
            <p><strong>STATEMENT KOMISI BULANAN</strong></p>
            <p>Periode : {{ statementCommission.period }}</p>
          </div>
          <div>
            <p>Kepada Yth,</p>
            <p>Bpk/Ibu {{ statementCommission.office.name }}</p>
            <p>{{ statementCommission.office.address }}</p>
            <p v-if="statementCommission.office.addres2">
              {{ statementCommission.office.addres2 }}
            </p>
            <p>{{ statementCommission.office.kta }}</p>
          </div>
        </div>
        <div class="grid gap-4 md:grid-cols-2">
          <div class="space-y-4 md:space-y-0">
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">No Tetap</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ statementCommission.office.code }}
                <template v-if="statementCommission.office.temp_code"
                  >/ {{ statementCommission.office.temp_code }}</template
                >
              </dd>
            </div>
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">Nama Mitra Usaha</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ statementCommission.office.name }}
              </dd>
            </div>
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">Peringkat</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ convertStage(statementCommission.office.stage) }}
              </dd>
            </div>
          </div>
          <div class="space-y-4 md:space-y-0">
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">No Upline</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ statementCommission.office.upline_code }}
              </dd>
            </div>
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">Nama Upline</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ statementCommission.office.upline_name }}
              </dd>
            </div>
            <div class="grid md:grid-cols-2">
              <dt class="text-xs text-gray-700 md:text-base md:text-gray-900">Peringkat Upline</dt>
              <dd class="font-bold text-gray-900 mt-1 md:mt-0 md:font-normal">
                <span class="hidden md:inline">: </span
                >{{ convertStage(statementCommission.office.upline_stage) }}
              </dd>
            </div>
          </div>
        </div>
      </base-card>
      <div class="grid gap-4 lg:grid-cols-2">
        <base-card class="overflow-x-auto" sm-none>
          <p><strong>Informasi Data Cashbill</strong></p>
          <div>
            <table class="w-full">
              <tr>
                <th class="text-left whitespace-nowrap pr-4">Tanggal</th>
                <th class="text-left whitespace-nowrap pr-4">No. Cashbill</th>
                <th class="text-right whitespace-nowrap pr-4">PV</th>
                <th class="text-right whitespace-nowrap pr-4">BV</th>
              </tr>
              <tr
                v-for="(trx, index) in statementCommission.transactions"
                :key="index"
              >
                <td>{{ trx.date }}</td>
                <td>{{ trx.office_code }}-{{ trx.code }}</td>
                <td class="text-right whitespace-nowrap pr-4">{{ trx.pv | toCurrency }}</td>
                <td class="text-right whitespace-nowrap pr-4">{{ trx.bv | toCurrency }}</td>
              </tr>
              <tr>
                <td colspan="2">Personal Omset</td>
                <td class="text-right whitespace-nowrap pr-4">
                  {{ statementCommission.office.pvpri | toCurrency }}
                </td>
                <td class="text-right whitespace-nowrap pr-4">
                  {{ statementCommission.office.bvpri | toCurrency }}
                </td>
              </tr>
              <tr>
                <td colspan="2">Omzet Group Total</td>
                <td class="text-right whitespace-nowrap pr-4">
                  {{ statementCommission.office.pvgroup | toCurrency }}
                </td>
                <td class="text-right whitespace-nowrap pr-4">
                  {{ statementCommission.office.bvgroup | toCurrency }}
                </td>
              </tr>
              <tr>
                <td colspan="2">Total Omzet</td>
                <td class="text-right whitespace-nowrap pr-4">{{ totalOmset.pv | toCurrency }}</td>
                <td class="text-right whitespace-nowrap pr-4">{{ totalOmset.bv | toCurrency }}</td>
              </tr>
              <tr v-if="statementCommission.office.stage === 'D'">
                <td colspan="2">Total PV Bulan Lalu</td>
                <td class="text-right whitespace-nowrap pr-4">
                  {{ statementCommission.office.pvlalu | toCurrency }}
                </td>
              </tr>
              <tr v-if="statementCommission.office.stage === 'D'">
                <td colspan="2">Total PV Akumulatif</td>
                <td class="text-right whitespace-nowrap pr-4">{{ totalPv | toCurrency }}</td>
              </tr>
            </table>
          </div>
        </base-card>
        <base-card class="space-y-2 md:space-y-0" sm-none>
          <p><strong>Informasi Data Komisi Bulanan</strong></p>
          <ul>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Pengembangan</p>
              <p class="">
                {{ statementCommission.office.rbt_kembang | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Karier</p>
              <p class="">
                {{ statementCommission.office.rbt_karir | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Kepemimpinan</p>
              <p class="">
                {{ statementCommission.office.rbt_pimpin | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Break Away</p>
              <p class="">
                {{ statementCommission.office.rbt_break_away | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Diamond</p>
              <p class="">
                {{ statementCommission.office.rbt_diamond | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Executive Diamond</p>
              <p class="">
                {{
                  statementCommission.office.rbt_executive_diamond | toCurrency
                }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Double Diamond</p>
              <p class="">
                {{ statementCommission.office.rbt_double_diamond | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Crown Diamond</p>
              <p class="">
                {{ statementCommission.office.rbt_crown_diamond | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Komisi Pass Up</p>
              <p class="">
                {{ statementCommission.office.rbt_pass_up | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.rbt_promo > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Rabat Promo</p>
              <p class="">
                {{ statementCommission.office.rbt_promo | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.rbt_bos > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Rabat Aksi Bos</p>
              <p class="">
                {{ statementCommission.office.rbt_bos | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.rbt_royalti > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Rabat Royalti Tahun 2021</p>
              <p class="">
                {{ statementCommission.office.rbt_royalti | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.bpud > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Biaya Perpanjangan Anggota</p>
              <p class="">{{ statementCommission.office.bpud | toCurrency }}</p>
            </li>
            <li class="flex justify-between">
              <p class="font-medium">Total Komisi Bulan ini</p>
              <p class="text-right">{{ totalCommission | toCurrency }}</p>
            </li>
            <li
              v-if="statementCommission.office.pajak > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Angsuran Pajak PPh 21</p>
              <p class="">
                {{ statementCommission.office.pajak | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.pending > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Akm Rabat Pending Bulan Lalu</p>
              <p class="">
                {{ statementCommission.office.pending | toCurrency }}
              </p>
            </li>
            <li
              v-if="statementCommission.office.biaya > 0"
              class="flex items-center justify-between"
            >
              <p class="font-medium">Biaya Transfer</p>
              <p class="">
                {{ statementCommission.office.biaya | toCurrency }}
              </p>
            </li>
            <li class="flex items-center justify-between">
              <p class="font-medium">Total Komisi Diterima</p>
              <p class="">{{ totalDiterima | toCurrency }}</p>
            </li>
          </ul>
        </base-card>
      </div>
      <base-card sm-none class="overflow-x-auto">
        <p><strong>Rincian Mitra Usaha Langsung</strong></p>
        <div>
          <table class="w-full">
            <tr>
              <th class="whitespace-nowrap text-left pr-4">No Tetap</th>
              <th class="whitespace-nowrap text-left pr-4">Nama Mitra Usaha</th>
              <th class="whitespace-nowrap text-left pr-4">Peringkat/PV Akm</th>
              <th class="whitespace-nowrap text-right pr-4">PV</th>
              <th class="whitespace-nowrap text-right pr-4">BV</th>
              <th class="whitespace-nowrap text-right pr-4">Pass Up</th>
            </tr>
            <tr
              v-for="(trx, index) in statementCommission.upline_transactions"
              :key="index"
            >
              <td class="whitespace-nowrap text-left pr-4">{{ trx.office_code }}</td>
              <td class="whitespace-nowrap text-left pr-4">{{ trx.office_name }}</td>
              <td class="whitespace-nowrap text-left pr-4">
                <span v-if="trx.stage !== 'D'">{{ trx.stage }}</span>
                <span v-else>
                  {{ (trx.pvlalu + trx.pvpri + trx.pvgroup) | toCurrency }}
                </span>
              </td>
              <td class="whitespace-nowrap text-right pr-4">
                <span v-if="trx.stage !== 'D'">
                  {{ (trx.pvpri + trx.pvgroup) | toCurrency }}
                </span>
              </td>
              <td class="whitespace-nowrap text-right pr-4">
                <span v-if="trx.stage !== 'D'">
                  {{ (trx.bvpri + trx.bvgroup) | toCurrency }}
                </span>
              </td>
              <td class="whitespace-nowrap text-right pr-4">{{ trx.dipup | toCurrency }}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td class="whitespace-nowrap text-right pr-4 font-bold">
                {{ totalUplineTrx.pv | toCurrency }}
              </td>
              <td class="whitespace-nowrap text-right pr-4 font-bold">
                {{ totalUplineTrx.bv | toCurrency }}
              </td>
              <td class="whitespace-nowrap text-right pr-4 font-bold">
                {{ totalUplineTrx.dipup | toCurrency }}
              </td>
            </tr>
          </table>
        </div>
      </base-card>
      <base-card sm-none>
        <p>
          {{ message }}
          <strong>{{ statementCommission.office.bonus_office }}</strong>
        </p>
        <p v-if="description">{{ description }}</p>
      </base-card>
      <base-card
        v-if="
          statementCommission.office.bpud > 0 &&
          statementCommission.office.bpud < 15000
        "
        sm-none
      >
        <div>
          <p>
            <strong><u>KTA BELUM DICETAK</u></strong>
          </p>
          <div class="flex justify-between md:grid md:grid-cols-12">
            <p class="font-medium md:col-span-7">
              Biaya Perpanjangan Keanggotaan
            </p>
            <p class="hidden md:block">:</p>
            <p class="text-right md:col-span-4">{{ 15000 | toCurrency }}</p>
          </div>
          <div class="flex justify-between md:grid md:grid-cols-12">
            <p class="font-medium md:col-span-7">
              Akumulasi Total Biaya Renual Lalu
            </p>
            <p class="hidden md:block">:</p>
            <p class="text-right md:col-span-4">
              {{ statementCommission.office.akmbpud | toCurrency }}
            </p>
          </div>
          <div class="flex justify-between md:grid md:grid-cols-12">
            <p class="font-medium md:col-span-7">
              Biaya Renual Dipotong Komisi Bulan Ini
            </p>
            <p class="hidden md:block">:</p>
            <p class="text-right md:col-span-4">
              {{ statementCommission.office.bpud | toCurrency }}
            </p>
          </div>
          <div class="flex justify-between md:grid md:grid-cols-12">
            <p class="font-medium md:col-span-7">Kekurangan Biaya Renual</p>
            <p class="hidden md:block">:</p>
            <p class="text-right md:col-span-4">
              {{
                Math.abs(
                  15000 -
                    statementCommission.office.akmbpud -
                    statementCommission.office.bpud
                )
              }}
            </p>
          </div>
        </div>
        <hr class="md:hidden" />
      </base-card>
      <base-card sm-none class="grid gap-4 md:grid-cols-2">
        <div>
          <p><strong>CHAMPIONT POINT 2023 - 2026 </strong></p>
          <p>Wisata Luar Negeri</p>
          <p>Dan Atau Wisata Regili</p>
          <br />
          <p class="flex justify-between pr-3">
            <span>Poin CP Lalu</span>
            <span>{{ statementCommission.office.cplalu | toCurrency }}</span>
          </p>
          <p class="flex justify-between pr-3">
            <span>Poin CP</span>
            <span>{{ statementCommission.office.cp | toCurrency }}</span>
          </p>
          <p class="text-right">--- +</p>
          <p class="flex justify-between pr-3">
            <span>TotalPoin CP</span>
            <span>{{
              (statementCommission.office.cp +
                statementCommission.office.cplalu +
                statementCommission.office.cptukar)
                | toCurrency
            }}</span>
          </p>
        </div>
        <div>
          <p><strong>CHAMPIONT</strong></p>
          <p>More Saving - More Holidays - More Religiously</p>
          <p>Periode : 1 Januari s/d 31 Desember 2023</p>
          <br />
          <p class="flex justify-between pr-3">
            <span>Poin Lalu</span>
            <span>{{ statementCommission.office.pmslalu | toCurrency }}</span>
          </p>
          <p class="flex justify-between pr-3">
            <span>Poin</span>
            <span>{{ statementCommission.office.pms | toCurrency }}</span>
          </p>
          <p class="text-right">--- +</p>
          <p class="flex justify-between pr-3">
            <span>Total Poin</span>
            <span>{{
              (statementCommission.office.pms +
                statementCommission.office.pmslalu +
                statementCommission.office.pmstukar)
                | toCurrency
            }}</span>
          </p>
        </div>
      </base-card>
      <base-card sm-none>
        <p>
          <u><strong>Informasi :</strong></u>
        </p>
        <p>
          <strong
            >SOSIALISASI PERATURAN MENTERI PERDAGANGAN TENTANG LARANGAN BAGI
            MITRA USAHA BERJUALAN DI MARKETPLACE DAN PERATURAN PERDAGANGAN
            MELALUI SISTEM ELEKTRONIK :
            <a href="https://member.naturalnusantara.co.id/fileinfo.php">
              KLIK DISINI</a
            ></strong
          >
        </p>
      </base-card>
      <base-card sm-none v-if="statementCommission.info">
        <p
          v-for="(info, key) in Object.values(statementCommission.info)"
          :key="key"
        >
          {{ info }}
        </p>
      </base-card>
    </base-card>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { sum } from '../../utils/array';

export default {
  mixins: [requestMixin],
  props: {
    officeId: String,
  },
  data() {
    return {
      loading: false,
      statementCommission: {
        period: null,
        office: {
          code: null,
          temp_code: null,
          name: null,
          stage: null,
          address: null,
          addres2: null,
          kta: null,
          upline_code: null,
          upline_name: null,
          upline_stage: null,
          rbt_kembang: null,
          rbt_karir: null,
          rbt_pimpin: null,
          rbt_break_away: null,
          rbt_diamond: null,
          rbt_executive_diamond: null,
          rbt_double_diamond: null,
          rbt_crown_diamond: null,
          rbt_pass_up: null,
          rbt_promo: null,
          rbt_bos: null,
          rbt_royalti: null,
          bpud: null,
          pvpri: null,
          bvpri: null,
          pvgroup: null,
          bvgroup: null,
          pajak: null,
          pending: null,
          biaya: null,
          pvlalu: null,
          stockist_code: null,
          transfer: null,
          bonus_office: null,
          bank: null,
          bank_branch: null,
          bank_no: null,
          bank_name: null,
          akmbpud: null,
          cp: null,
          cplalu: null,
          cptukar: null,
          pms: null,
          pmslalu: null,
          pmstukar: null,
          total_diterima: null
        },
      },
      transactions: [],
      upline_transactions: [],
      info: {},
    };
  },
  computed: {
    message() {
      if (
        !this.statementCommission.office.stockist_code &&
        this.statementCommission.office.transfer === 'x'
      ) {
        return `Tempat Pengambilan Statement di Stockist`;
      }

      return 'Tempat Pengambilan Komisi di Stockist';
    },
    description() {
      if (
        !this.statementCommission.office.stockist_code &&
        this.statementCommission.office.transfer === 'x'
      ) {
        if (this.statementCommission.period.split(' ')[0] === 'Oktober') {
          return this.totalDiterima < 25000
            ? `Komisi dipending ke Rekening ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`
            : `Komisi dipending ke Rekening ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`;
        }

        return this.statementCommission.office.biaya > 0
          ? `"Komisi ditransfer ke Rekening Bank ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`
          : `Komisi dipending / diakumulasikan dan jika telah memenuhi syarat akan ditransfer ke Rekening Bank ${this.statementCommission.office.bank}  Cabang ${this.statementCommission.office.bank_branch} No. Rekening ${this.statementCommission.office.bank_no} Atas Nama ${this.statementCommission.office.bank_name}`;
      }

      return false;
    },
    totalCommission() {
      return (
        this.statementCommission.office.rbt_kembang +
        this.statementCommission.office.rbt_karir +
        this.statementCommission.office.rbt_pimpin +
        this.statementCommission.office.rbt_break_away +
        this.statementCommission.office.rbt_diamond +
        this.statementCommission.office.rbt_executive_diamond +
        this.statementCommission.office.rbt_double_diamond +
        this.statementCommission.office.rbt_crown_diamond +
        this.statementCommission.office.rbt_promo +
        this.statementCommission.office.rbt_pass_up -
        this.statementCommission.office.bpud +
        this.statementCommission.office.rbt_bos +
        this.statementCommission.office.rbt_royalti
      );
    },
    totalOmset() {
      return {
        pv:
          this.statementCommission.office.pvpri +
          this.statementCommission.office.pvgroup,
        bv:
          this.statementCommission.office.bvpri +
          this.statementCommission.office.bvgroup,
      };
    },
    totalPv() {
      return (
        this.statementCommission.office.pvlalu +
        this.statementCommission.office.pvpri +
        this.statementCommission.office.pvgroup
      );
    },
    totalDiterima() {
      return this.statementCommission.office.total_diterima
    },
    totalUplineTrx() {
      return {
        pv: sum(
          this.statementCommission.upline_transactions ?? [],
          (i) => i.pvpri + i.pvgroup
        ),
        bv: sum(
          this.statementCommission.upline_transactions ?? [],
          (i) => i.bvpri + i.bvgroup
        ),
        dipup: sum(
          this.statementCommission.upline_transactions ?? [],
          (i) => i.dipup
        ),
      };
    },
  },
  methods: {
    convertStage(stage) {
      return {
        D: 'Mitra Usaha',
        M: 'Manager',
        SM: 'Silver Manager',
        GM: 'Gold Manager',
        PM: 'Pearl Manager',
        EM: 'Emerald Manager',
        DD: 'Diamond Director',
        EDD: 'Executive Diamond Director',
        DDD: 'Double Diamond Director',
        CDD: 'Crown Diamond Director',
      }[stage];
    },
    async loadCommission() {
      this.loading = true

      const [res, err] = await this.request(
        `/api/v1/offices/${this.officeId}/-actions/statement-commission`
      );

      if (!err) {
        this.statementCommission = res;
      }

      this.loading = false
    },
  },
  mounted() {
    this.loadCommission();
  },
};
</script>
